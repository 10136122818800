<template>
    <v-container>
        <v-card>
            <v-card-title>
                Verify Your Email Address
            </v-card-title>

            <v-card-text>
                <p>
                    Thanks for registering! We've sent a verification link to your email address.
                </p>
                <p>
                    Please check your email and click on the link to verify your account.
                </p>
                <br />
                <v-row>
                    <v-btn color="primary" @click="resendVerificationEmail">
                        Resend Verification Email
                    </v-btn>
                </v-row>
                <br />
                <br />
                <v-row>
                    <v-btn :loading="isLoading" color="secondary" @click="checkEmailVerification">
                        Check Verification and Continue
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" :color="snackbarColor">
            {{ snackbarText }}
        </v-snackbar>

    </v-container>
</template>
  
<script>
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';
import { mapGetters } from "vuex";

export default {
    data: () => ({
        snackbar: false,
        snackbarColor: 'error',
        snackbarText: '',
        isLoading: false
    }),
    computed: {
        ...mapGetters([
            'user'
        ])
    },
    methods: {
        async resendVerificationEmail() {
            const auth = getAuth();
            const user = auth.currentUser;

            if (user) {
                try {
                    await sendEmailVerification(user);
                    this.snackbarText = 'Verification email sent successfully';
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                } catch (error) {
                    console.error("Error resending verification email: ", error);
                    this.snackbarText = 'Failed to resend verification email. Please try again later.';
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                }
            }
        },
        async checkEmailVerification() {
            this.isLoading = true;
            const auth = getAuth();
            const user = auth.currentUser;

            // Refresh the user's authentication state
            if (user) {
                await user.reload();

                if (user.emailVerified) {
                    // Initialize Firestore
                    const db = getFirestore();
                    const userDocRef = doc(db, 'users', user.uid);

                    // Fetch the current user document
                    const userDocSnap = await getDoc(userDocRef);

                    // Check if Stripe customer has been created
                    if (userDocSnap.exists && !userDocSnap.data().stripe_customer_id) {
                        try {
                            // Create Stripe customer
                            const token = this.user.accessToken;
                            const headers = {
                                'Authorization': `Bearer ${token}`
                            };
                            const stripeResponse = await axios.post(`${process.env.VUE_APP_API_ADDRESS}/create_stripe_customer`, {
                                user_id: user.uid,
                                email: user.email,
                                subscription_level: 'Free',
                            }, {
                                headers: headers
                            });

                            // Update Firestore document to store the Stripe customer ID and emailVerified flag
                            await updateDoc(userDocRef, {
                                stripe_customer_id: stripeResponse.data.stripe_customer_id,
                                emailVerified: true
                            });

                        } catch (error) {
                            console.error('Error creating Stripe customer:', error);
                        }
                    } else if (userDocSnap.exists && userDocSnap.data().emailVerified !== true) {
                        // Update just the emailVerified flag if needed
                        await updateDoc(userDocRef, {
                            emailVerified: true
                        });
                    }
                    // Add user to convertkit
                    try {
                        const token = this.user.accessToken;
                        const headers = {
                            'Authorization': `Bearer ${token}`
                        };
                        axios.post(`${process.env.VUE_APP_API_ADDRESS}/convertkit/add_user`, {
                            email: user.email,
                            first_name: user.displayName
                        }, {
                            headers: headers
                        }).then(response => {
                            // Handle success here if you want
                        }).catch(error => {
                            // Handle Axios-specific errors here if you want
                            console.error('Axios-specific error:', error);
                        });
                    } catch (error) {
                        console.error('Error adding user to ConvertKit:', error);
                    }

                    // Redirect to the home page after all the updates
                    this.$router.push('/');
                } else {
                    // Show a snackbar message if the email is not verified
                    this.snackbarText = 'Email not verified yet. Please check your inbox and verify your email.';
                    this.snackbarColor = 'warning';
                    this.snackbar = true;
                }
            }
            this.isLoading = false;
        }

    },
};
</script>
